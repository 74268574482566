<template>
  <div style="height:100%">
    <page-block
      v-for="renderBlock in renderBlocks"
      :key="renderBlock.id"
      :render-block="renderBlock"
    />
  </div>
</template>

<script>
import PageBlock from "./PageBlock"

export default {
  name: "PageBuilder",
  components: {
    PageBlock,
  },
  props: {
    renderBlocks: {
      default: () => [],
      type: Array,
    },
  },
}
</script>
